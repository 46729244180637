import React from 'react';
import { Helmet } from 'react-helmet';

const YoutubeApi = () => {
	return ( 
		<Helmet>
			<script src="https://apis.google.com/js/platform.js"></script>
		</Helmet>
	);
};

export default YoutubeApi;